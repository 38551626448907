





















































































import { Component, Vue } from 'vue-property-decorator'
import CustomerInfo from '@/components/desktop-pos/customer-info.vue'
import CartItems from '@/components/desktop-pos/cart-items.vue'
import PriceSummary from '@/components/desktop-pos/price-summary.vue'
import MemberCard from '@/components/desktop-pos/member-card.vue'
import Receipt from '@/components/Receipt.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import DesktopPosNavbar from '@/components/desktop-pos/navbar.vue'

@Component({
  components: {
    CustomerInfo,
    CartItems,
    PriceSummary,
    MemberCard,
    Receipt,
    DesktopPosNavbar
  },
  computed: {
    ...mapState({
      selectBranch: (state: any) => state.Store.selectBranch,
      selectPos: (state: any) => state.Store.selectPos
    }),
    ...mapGetters({
      net: 'Cart/net',
      memberTel: 'Cart/tel',
      paymentChannels: 'Store/paymentChannels'
    })
  },
  methods: {
    ...mapActions({
      previewReceipt: 'Order/previewReceipt',
      setPayment: 'Cart/setPayment'
    })
  }
})
export default class PaymentChannel extends Vue {
  net!: any

  previewReceipt!: any

  preReceipt: any = null

  selectBranch!: any

  selectPos!: any

  showFullPriceFormat!: any

  memberTel!: any

  setPayment!: any

  paymentChannels!: any[]

  async mounted (): Promise<any> {
    this.preReceipt = await this.previewReceipt({ status: 'no_print', ip: 'no print' })
  }

  checkout (payment: any): void {
    if (payment.type === 'cash') {
      this.$router.push({ name: 'CashCheckout' })
    } else {
      this.$router.push({ name: 'CardCheckout' })
    }
    this.setPayment(payment)
  }

  get sumAmount (): string {
    if (!this.preReceipt) return '-'
    return `${this.preReceipt.items.reduce((sum: number, d: any) => sum + d.amount, 0) }`
  }
}
